body{
    background-color: rgb(35, 40, 40);
    color: aliceblue;
  }
  .navbar{
    height: 5rem;
  }
  .footer{
    height: 1rem;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Ensures padding doesn't affect overall dimensions */
  }
  
  footer p {
    margin-bottom: 0; /* Specifically removes margin from the paragraph in footer */
  }
  .container, .row, .footer {
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .accordion{
    
    width: 80%;
  }
  
  
  
  